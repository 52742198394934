import axios from 'axios'
import { RouteApi, RouteBase, RouteSSO } from './config'


const Post = (path, root, data, adtUrl = null, ssoLogin = false) => {
    const promise = new Promise((resolve, reject) => {
        const defaultGateway = root == false ? RouteApi + adtUrl : RouteBase
        const ssoGateway = ssoLogin ? RouteSSO : defaultGateway
        
        axios.post(`${ssoGateway}${path}`, data, {
            // resonse_object.header();
            // resonse_object.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
        })
            .then(function (response) {
                // handle success
                resolve(response);
            })
            .catch(function (error) {
                // handle error
                reject(error);
            })
    })
    return promise
}

export default Post