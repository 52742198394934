import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    profile: {},
}

export const profileSlice = createSlice({
    name:'profile',
    initialState,
    reducers:{
        setProfile: (state, action) =>{
            state.profile = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setProfile } = profileSlice.actions

export default profileSlice.reducer