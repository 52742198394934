import { createAsyncThunk, createSlice, isFulfilled, isRejectedWithValue } from "@reduxjs/toolkit";
import API from "../../../services";

const initialState = {
    loggedIn: false,
    access_token: {},
    alert: "",
    profile: {
        role_id : null,
    },
}


export const postLogin = createAsyncThunk(
    'auth/login',
    async ({ email, password, g_token }, isRejectedWithValue) => {
        let formData = {
            email,
            password,
            g_token
        }
        try {
            const response = await API.postLogin(formData)
            // console.log("SSO LOGIN", response)
            return response.data
        } catch (error) {
            // throw isRejectedWithValue(error)
            // console.log("SSO ERROR", error.response)
            
            if(error.response.status === 503){
                throw error.response.status
            }

            throw error.response.data
        }

    }
)

export const postBridge = createAsyncThunk(
    'auth/bridge',
    async ({ code }, isRejectedWithValue) => {
        try {
            // console.log("DISPATCHING POST BRIDGE", code)
            const response = await API.getBridge(code)
            return response
        } catch (error) {
            throw isRejectedWithValue(error)
        }
    }
)

export const postChangePassword = createAsyncThunk(
    'auth/changePassword',
    async ({ old_password, password, password_confirmation }, {rejectWithValue}) => {
        let formData = {
            old_password,
            password,
            password_confirmation
        }

        // console.log("FORMDATA", formData)
        try {
            const response = await API.postChangePassword(formData)
            return response
        } catch (error) {
            // console.log("MASUK REJECTED")
            if (!error.response) {
                throw error
              }
              return rejectWithValue(error.response.data)
        }
    }
)




export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        isLoggedIn: (state, action) => {
            state.loggedIn = true
            state.access_token = action.payload
            localStorage.setItem("access_token", action.payload)
        },
        isLoggedOut: (state) => {
            state.profile = {},
                state.access_token = null
            state.loggedIn = false
            localStorage.setItem("access_token", null)
        },
        setProfile: (state, action) => {
            state.profile = action.payload.profile
        },
    },
    extraReducers: (builder) => {
        builder.addCase(postLogin.fulfilled, (state, action) => {
            // console.log("ASYNC THUNK FROM SLICE", action)
            if(action.payload.platforms.includes("vendor")){
                state.loggedIn = true
                state.profile = action.payload
                state.access_token = action.payload.access_token
                localStorage.setItem('access_token', action.payload.access_token)
            }else{
                state.loggedIn = false
            }
            // return action
        }).addCase(postLogin.rejected, (state, action) => {
            // console.log("ASYNC REJECTED", action)
            // state.loggedIn = false
            // state.alert
        }).addCase(postBridge.fulfilled, (state, action) => {
            state.loggedIn = true
            // console.log("hasil dari post bridge", action)
            state.access_token = action.payload.access_token
            state.profile = action.payload.user
            localStorage.setItem("access_token", action.payload.access_token)

            let formated = action.payload.redirect_to.replaceAll('project', '')
            formated = formated.replaceAll('/', '')
            // localStorage.setItem('redirect_to', formated)
            // console.log("Bridge berhasil")

        }).addCase(postBridge.rejected, (state, action) => {
            state.loggedIn = false
            // console.log("BRIDGE REJECTED")

        }).addCase(postChangePassword.fulfilled, (state, action) => {
            // console.log("change password berhasil")

        }).addCase(postChangePassword.rejected, (state, action) => {
            // console.log("change password gagal", action.payload)
        })
    }
})


// Action creators are generated for each case reducer function
export const { isLoggedIn, setProfile, isLoggedOut } = authSlice.actions

export default authSlice.reducer